import React from 'react';
import Slider from 'react-slick';
import './SliderCard.css'; // Ensure this path is correct
import logo1 from '../Assets/logo1.png';
import logo2 from '../Assets/logo2.png';
import logo3 from '../Assets/logo3.png';
import logo4 from '../Assets/logo4.jpg';
import logo5 from '../Assets/logo5.webp';

const SliderCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4, // Show 3 slides for widths below 1200px
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3, // Show 2 slides for widths below 992px
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 1 slide for widths below 768px
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2, // Show 2 slides for widths below 650px
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1, // Show 1 slide for widths below 550px
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1, // Keep 1 slide for widths below 450px
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1, // Still keep 1 slide for widths below 350px
        },
      },
    ],
  };

  return (
    <div className="slider-container">
        <div className="slider-element">
            <h1>Our Esteemed Partners</h1>
        </div>
      <Slider {...settings}>
        <div className="slider-card">
          
          <div className="card-footer">
            <img src={logo1} alt="Reviewer 1" className="card1" />
           
          </div>
        </div>
        <div className="slider-card">
          
          <div className="card-footer">
            <img src={logo2} alt="Reviewer 2" className="card-avatar" />
           
          </div>
        </div>
        <div className="slider-card">
        
          <div className="card-footer">
            <img src={logo3} alt="Reviewer 3" className="card3" />
          
          </div>
        </div>
        <div className="slider-card">
          
          <div className="card-footer">
            <img src={logo4} alt="Reviewer 4" className="card4" />
          </div>
        </div>
        <div className="slider-card">
          
          <div className="card-footer">
            <img src={logo5} alt="Reviewer 5" className="card5" />
      
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderCard;
