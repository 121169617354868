import React, { useState, useRef, useEffect } from "react";
import Home1 from "../Assets/home1.png";
import a1 from "../Assets/a1.png";
import advertise from "../Assets/advertise1.jpg";
import playbutton from "../Assets/playbutton.svg";
import truck from "../Assets/truck.png";
import videoFile from "../Assets/video.mp4"; // Import your video
import "./Advertisers.css";

const Advertisers = () => {
  const [showVideo, setShowVideo] = useState(false);
  const videoModalRef = useRef(null); // Correct state for video modal

  const handleVideoPlay = () => {
    setShowVideo(true); // Correct state handler
  };

  const handleCloseVideo = () => {
    setShowVideo(false); // Correct state handler
  };

    const handleClickOutside = (event) => {
      // Check if click is outside the video modal
      if (
        videoModalRef.current &&
        !videoModalRef.current.contains(event.target)
      ) {
        handleCloseVideo();
      }
    };

    useEffect(() => {
      if (showVideo) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showVideo]);


  return (
    <div className="advertisers-wrapper">
      <div className="advertisers-image-container">
        <img src={Home1} alt="about" className="advertisers-background-image" />
        <h1 className="advertisers-heading">Advertisers</h1>
      </div>

      <div className="advertisers-img">
        <img src={a1} alt="car" className="car-img" />
      </div>

      <div className="advertise-flex">
        <div className="img-descrip">
          <img src={advertise} alt="advertise" className="advertise-img" />
        </div>
        <div className="explain-descrip">
          <h3 className="h3-heading">
            Challenges with Traditional OOH Advertising
          </h3>
          <ul className="flex-ul">
            <li className="flex-li">One of the oldest advertising forms.</li>
            <li className="flex-li">
              Difficulty in measuring and proving impact.
            </li>
            <li className="flex-li">
              Shift in expectations with the rise of online digital
              advertising's targeting and reporting.
            </li>
          </ul>
          <p className="para-descrip">Why Us?</p>
          <p>
            <strong>Innovative Solutions:</strong> Cutting-edge multimedia tech
            which helps connect with your target audience 10 times faster &amp;
            improves ROI.
          </p>
          <p>
            <strong>Programmatic DOOH:</strong> Tech-savvy, cloud-based ad
            campaigns for public spaces including busy streets. Broaden your
            audience and gain a competitive advantage.
          </p>
          <p>
            <strong>Tailored Campaigns:</strong> Customized strategies to meet
            your brand’s unique needs and goals. Real-time systems ensure
            maximum visibility for your brand.
          </p>
          <p>
            <strong>Enhanced Experiences:</strong> High-definition displays, 3D
            graphics, and AR for impactful, interactive ads to a mass audience
            for superior brand exposure.
          </p>
        </div>
      </div>

      <div className="content-play">
        <div>
          <h3>Elevate Your Brand with Innovative Multimedia Solutions</h3>
        </div>
        <div className="img-vid">
          <a
            href="a"
            onClick={(e) => {
              e.preventDefault();
              handleVideoPlay();
            }}
          >
            <img className="play-button1" alt="Play" src={playbutton} />
          </a>
        </div>
      </div>

      {showVideo && (
        <div
          className="video-modal"
          ref={videoModalRef}
          onClick={handleCloseVideo}
        >
          {/* <button className="close-button1" onClick={handleCloseVideo}>
            X
          </button> */}
          <div className="video-content" onClick={(e) => e.stopPropagation()}>
            <video controls className="video-player" autoPlay>
              <source src={videoFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}

      <div className="solutions">
        <div className="flex1">
          <h3 className="head-flex">Dynamic Multimedia Solutions</h3>
          <p className="para-flex">
            Create immersive brand experiences with our cutting-edge multimedia
            solutions.
          </p>
        </div>
        <div className="flex2">
          <img src={truck} alt="truck" className="img-truck" />
        </div>
      </div>
    </div>
  );
};

export default Advertisers;
