import React, { useState } from "react";
import Home1 from "../Assets/home1.png";
import TransformImage from "../Assets/transformimage.png";
import SmartCar from "../Assets/innovativecar.png"
import Vision from "../Assets/vision.png";
import Mission from "../Assets/mission.png";
import HazyImage from "../Assets/hazy.png";
import SliderCard from "./SliderCard";
import "./About.css";

const About = () => {
  const [open, setOpen] = useState(null);

  const toggleDropdown = (index) => {
    setOpen(open === index ? null : index);
  };

  const faqData = [
    {
      question: "Programmatic DOOH Platform",
      answer: [
        "Tech-savvy campaigns run over the cloud.",
        "Leverages digital ads in public spaces.",
      ],
    },
    {
      question: "Tailored Multimedia Solutions",
      answer: [
        "Customized to unique brand needs and goals.",
        "Focus on brand awareness, sales, and product promotion.",
        "Collaborative development of resonant campaigns.",
      ],
    },
    {
      question: "Cutting-Edge Technology",
      answer: [
        "Utilizes high-definition displays, 3D graphics, and augmented reality.",
        "Delivers immersive, interactive experiences.",
        "Employs latest tools for memorable campaigns.",
      ],
    },
    {
      question: "Accurate Tracking",
      answer: ["Monitor your Ad performance on our dashboard."],
    },
    {
      question: "Real-Time Optimization",
      answer: ["Maximize your impact with agile campaign management."],
    },
  ];
  return (
    <div className="about-wrapper">
      <div className="about-image-container">
        <img src={Home1} alt="about" className="about-background-image" />
        <h1 className="about-heading">About Us</h1>
      </div>

      <div className="transform-section">
        <div className="transform-left">
          <h2 className="transform-left-heading">Transforming Advertising</h2>
          <p className="transform-left-paragraph">
            At Adeiaz, we transform brand engagement with cutting-edge
            advertising solutions that deliver measurable results. While
            traditional OOH faced challenges with impact measurement, digital
            OOH now provides precise targeting, real-time analytics, and dynamic
            visuals. Our advanced digital signage and ad-serving tech redefine
            advertising, ensuring brands reach and resonate with audiences
            effectively and at scale.
          </p>
          <p className="transform-left-paragraph">
            Advertisers can use DOOH to serve consumers with relevant content
            that cuts through the cluttered digital ad landscape and leaves a
            lasting and meaningful impression without being intrusive.
          </p>
          <p className="transform-left-paragraph">
            DOOH also significantly eliminates privacy concerns. With
            geo-fencing and commute time as targeting metrics, ads on our assets
            grab attention and boost brand recall, to drive sales.
          </p>
        </div>
        <div className="transform-right">
          <img
            src={TransformImage}
            alt="Transform"
            className="transform-image"
          />
        </div>
      </div>

      <div className="innovative-section">
        <div className="innovative-right">
          <div className="Innovative-card card-left vision-card">
            <img src={Vision} alt="Vision" className="Innovative-card-image" />
            <div className="Innovative-card-content">
              <h3 className="Innovative-card-heading">Vision Statement</h3>
              <p className="Innovative-card-paragraph">
                To transform the advertising landscape by pioneering innovative
                solutions that empower brands to create authentic connections
                with their audiences, inspiring engagement and fostering lasting
                relationships.
              </p>
            </div>
          </div>
        </div>

        <div className="innovative-left">
          <div className="content-card">
            <h2 className="innovative-left-heading">
              Innovative Multimedia Solutions
            </h2>
            <ul className="innovative-pointers">
              <li>Advanced and smarter solutions for products and services.</li>
              <li>Based on thorough market research and latest technology.</li>
              <li>Ensures better Return on Investment (ROI).</li>
            </ul>
          </div>
        </div>
        <img src={SmartCar} alt="innovative" className="innovative-car" />

        {/* <div className="innovative-right">
          <div className="Innovative-card card-left">
            <img src={Vision} alt="Vision" className="Innovative-card-image" />
            <div className="Innovative-card-content">
              <h3 className="Innovative-card-heading">Vision Statement</h3>
              <p className="Innovative-card-paragraph">
                To transform the advertising landscape by pioneering innovative
                solutions that empower brands to create authentic connections
                with their audiences, inspiring engagement and fostering lasting
                relationships.
              </p>
            </div>
          </div>
        </div> */}

        <div className="innovative-right">
          <div className="Innovative-card card-right mission-card">
            <img
              src={Mission}
              alt="Mission"
              className="Innovative-card-image"
            />
            <div className="Innovative-card-content">
              <h3 className="Innovative-card-heading">Mission Statement</h3>
              <p className="Innovative-card-paragraph">
                At DigiAdeiaz, we are committed to transforming the advertising
                landscape. By leveraging cutting-edge technology, compelling
                storytelling, and data-driven insights, we empower our clients
                to achieve remarkable results. We strive to create unforgettable
                experiences that not only engage audiences but also elevate
                brands to new heights.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="faq-section">
        <div className="faq-left-section">
          <h3 className="faq-subheading">
            Innovative and Disruptive way of Advertising with Adeiaz
          </h3>
          {/* <p className="faq-description">
            Got Questions? Dive into our FAQs and find the missing puzzle piece
            to your learning journey!
          </p> */}
        </div>
        <div className="faq-right-section">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleDropdown(index)}
              >
                <span className="faq-text">{item.question}</span>
                {open === index ? <span>&#9660;</span> : <span>&#9658;</span>}
              </div>
              {open === index && (
                <div className="faq-answer">
                  <ul className="faq-answer-list">
                    {item.answer.map((point, idx) => (
                      <li key={idx}>{point}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <SliderCard />

      <div className="hazy-section">
        <img src={HazyImage} alt="Hazy" className="hazy-image" />
      </div>
    </div>
  );
};

export default About;
