import React from 'react'
import Home1 from "../Assets/home1.png";
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <div className="policy-content">
      <div className="policy-image-container">
        <img src={Home1} alt="home1" className="policy-background-image" />
        <h1 className="policy-heading">Privacy Policy</h1>
      </div>

      <div className="policy-container">
        <div className="policy-para">
          <h2>Our Commitment To Privacy</h2>
          <p>
            Digiadeaiz __. is committed to protecting your privacy. In order to obtain more information about our
            products and services, it is the policy of Digiadeaiz __ that personal information, such as your name,
            personal postal and email address, or personal telephone number is private and confidential. Because your
            privacy is important to us, Digiadeaiz __ maintains privacy policies to protect your personal information.
            We may use your personal information to market third party products and services to you. However, we shall
            obtain your permission prior to providing your details to such third parties and every time we email you for
            third party marketing purposes we will give you the chance to refuse any such emails in the future. To better
            protect your privacy we provide this notice explaining our online information practices and the choices you can
            make about the way your information is collected and used.
          </p>

          <h2>Website Privacy Policy</h2>
          <p>
            By using this site, you consent to the terms of our privacy policy for the collection, use and disclosure of
            your personal information for the purposes set out below. We do not collect, use or disclose your personal
            information for any purpose other than those identified below, except with your consent or as required by law.
          </p>

          <h2>The Information We Collect</h2>
          <p>
            This notice applies to all information collected or submitted on the Digiadeaiz web site. On some pages, you can
            order services and make requests. The types of personal information collected at these pages shall be the
            following including without limitation:
          </p>
          <ul>
            <li>Name</li>
            <li>Address</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>IP Address</li>
            <li>Driver’s license</li>
            <li>Vehicle registration number</li>
            <li>Photo, media, etc.</li>
          </ul>

          <h2>Policy Revisions</h2>
          <p>
            Any changes to the privacy policy will be promptly communicated on this website. Policy changes will not alter
            how we handle previously submitted personal information. If you have any questions about our privacy policies or
            wish to update any of the personal information you have provided to us, contact us at ______.
          </p>

          <h2>The Way We Use Information</h2>
          <p>
            Our primary goal in collecting your Personal information or Usage Information is to provide you with an enhanced
            experience when using the Services. We use your nonpublic personal information/data in order to match you with
            advertisers and to otherwise assist you and allow you to perform the Services, respond to your requests,
            customize your experience on the Digiadeaiz website and contact you regarding your account. If you give us
            permission, we will also use your information to make you aware of additional products and services that may be
            of interest to you or to contact you regarding site changes.
          </p>

          <p>
            We send you emails relating to your transactions and announcements related to personal services, but only when
            necessary. You will also receive additional notifications from us that may be waived if you choose (via optout).
            Based upon the Personal Information you provide us when registering for an account, we will send you a welcome
            email. We will also communicate with you in response to your inquiries, to provide the services you request, and
            to manage your account. We will communicate with you by email, telephone, or SMS.
          </p>

          <p>
            We use your information to closely monitor which features of the Services are used most, to allow you to view
            your commuting history, view any promotions we may currently be running, rates applicable to your commutes, and
            to determine which features we need to focus on improving, including usage patterns and geographic locations to
            determine where we should offer or focus services, features and/or resources. We use your Internet Protocol (IP)
            address to help diagnose problems with our computer server, and to administer our web site(s). Your IP address
            is used to help identify you, but contains no personal information about you.
          </p>

          <p>We will send you strictly service-related announcements on occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account. In addition, we may use your Personal Information or Usage Information that we collect about you:</p>

<p>To provide you with information or services or process transactions that you have requested or agreed to receive including to send you electronic newsletters, or to provide you with special offers or promotional materials on behalf of us or third parties.</p>

<p>To process your registration with the Services, including verifying your information is active and valid.</p>

<p>To improve the platform, to customize your experience with the platform, or to serve you specific content that is most relevant to you.</p>

<p>To enable you to participate in a variety of the Services’ features such as online or mobile promotions.</p>

<p>To contact you with regard to your use of the Services and, in our discretion, changes to the Services and/or the Services’ policies.</p>

<p>For internal business purposes.</p>

<p>For inclusion in our data analytics.</p>

<p>For purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy</p>

<p>Please note that information submitted to the Services via a “contact us” or other similar function may not receive a response.</p>

          <h2>Links</h2>
          <p>
            This web site contains links to other sites. Please be aware that we are not responsible for the privacy
            practices of such other sites. We encourage our users to be aware when they leave our site and to read the
            privacy statements of each and every web site that collects personally identifiable information. This privacy
            statement applies solely to information collected by this Web site.
          </p>

          <h2>Security</h2>
          <p>
            The security of your personal information is important to us. We follow generally accepted industry standards to
            protect the personal information submitted to us, both during transmission and once we receive it. No method of
            transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we
            strive to use commercially acceptable means to protect your personal information, we cannot guarantee its
            absolute security. If you have any questions about security on our Website, you can contact us at Digiadeaiz ______.com.
          </p>

          <h2>How To Contact Us</h2>
          <p>
            Should you have other questions or concerns about these privacy policies, please contact us at Digiadeaiz
            __.com
          </p>

          <h2>Notification of Changes</h2>
          <p>
            If we decide to change our privacy policy, we will post those changes on our Home page so our users are always aware of what information we collect, how we use it,
            and under circumstances, if any, we disclose it.
            If at any point we decide to use personally identifiable information in a manner different from that stated at the time it was collected, we will notify users by way of an email or SMS or such other mediums. Users will have a choice as to whether or not we use their information in this different manner.
            We will use information in accordance with the privacy policy under which the information was collected.
          </p>
        </div>
      </div>
    </div>

  );
}

export default PrivacyPolicy