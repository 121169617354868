import React from 'react'
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import './GlobalIcons.css'

const GlobalIcons = () => {
  return (
    <div className="social-icons">
      <a
        href="https://www.linkedin.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#0077B5" }}
      >
        <FaLinkedin />
      </a>
      <a
        href="https://www.instagram.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#E1306C" }}
      >
        <AiFillInstagram />
      </a>
      <a
        href="https://twitter.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#1DA1F2" }}
      >
        <FaTwitter />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=917338562232&text&type=phone_number&app_absent=0"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#25D366" }}
      >
        <RiWhatsappFill />
      </a>
    </div>
  );
}

export default GlobalIcons