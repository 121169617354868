import React from 'react'
import Home1 from "../Assets/home1.png"
import './Dashboard.css'

const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-image-container">
        <img src={Home1} alt="about" className="dashboard-background-image" />
        <h1 className="dashboard-heading">Welcome</h1>
      </div>
    </div>
  );
}

export default Dashboard