import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./GlobalHeader.css";
import Logo from "../Assets/headerlogo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoLockClosedOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";

const GlobalHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLoginClick = () => {
    setIsPopupOpen(true);
    setErrorMessage("");
   setIsMobile(false);   
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setErrorMessage("");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    const validEmail = "brand@adeiaz.com";
    const validPassword = "Adeiaz@123";

    if (email === validEmail && password === validPassword) {
      setEmail("");
      setPassword("");
      setIsPopupOpen(false);
      navigate("/dashboard");
    } else {
      setErrorMessage("Invalid Credentials");
    }
  };

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setIsPopupOpen(false);
    }
  }, [location.pathname]);

  return (
    <header className="global-header">
      <img src={Logo} alt="header-Logo" className="header-logo" />
      <nav className={`header-nav ${isMobile ? "mobile-nav" : ""}`}>
        {isMobile && (
          <div className="mobile-header">
            <button
              className="close-mobile-nav"
              onClick={() => setIsMobile(false)}
            >
              X
            </button>
          </div>
        )}
        <NavLink
          to="/"
          onClick={() => setIsMobile(false)}
          className={({ isActive }) =>
            isActive ? "nav-link active-link" : "nav-link"
          }
        >
          HOME
        </NavLink>
        <NavLink
          to="/about"
          onClick={() => setIsMobile(false)}
          className={({ isActive }) =>
            isActive ? "nav-link active-link" : "nav-link"
          }
        >
          ABOUT US
        </NavLink>
        <NavLink
          to="/advertisers"
          onClick={() => setIsMobile(false)}
          className={({ isActive }) =>
            isActive ? "nav-link active-link" : "nav-link"
          }
        >
          ADVERTISERS
        </NavLink>
        <NavLink
          to="/contact"
          onClick={() => setIsMobile(false)}
          className={({ isActive }) =>
            isActive ? "nav-link active-link" : "nav-link"
          }
        >
          CONTACT
        </NavLink>
        {isMobile && (
          <button
            className="mobile-login"
            onClick={handleLoginClick}
          >
            LOGIN
          </button>
        )}
      </nav>
      <div className="header-actions">
        {!isMobile && (
          <button className="login-button" onClick={handleLoginClick}>
            LOGIN
          </button>
        )}

        <div className="burger-menu" onClick={() => setIsMobile(!isMobile)}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="login-popup">
          <div className="popup-content">
            <button className="close-popup" onClick={handleClosePopup}>
              X
            </button>
            <span className="Span-popup">______</span>
            <h2 className="popup-heading">Login to Your Account</h2>
            <p className="popup-text">
              Access your dashboard, manage campaigns, and optimize your brand's
              exposure.
            </p>

            <form className="login-box" onSubmit={handleLoginSubmit} noValidate>
              <div className="input-container">
                <CiMail className="icon1" />
                <input
                  className="login-input"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="advertise@adeiaz.com"
                  required
                />
              </div>
              <div className="input-container">
                <IoLockClosedOutline className="icon2" />
                <input
                  className="login-input"
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  placeholder="**********"
                  required
                />
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <button type="submit" className="sign-in-button">
                Sign In
              </button>
            </form>
          </div>
        </div>
      )}
    </header>
  );


};

export default GlobalHeader;