import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./GlobalFooter.css";
import Logo from "../Assets/footerlogo.png"; 

const GlobalFooter = () => {
  return (
    <footer className="global-footer">
      <div className="footer-sections">
        <div className="footer-section section-1">
          <img src={Logo} alt="Footer Logo" className="footer-logo" />
          <p>Unleash Dynamic Ads on the Move!</p>
        </div>

        <div className="footer-section section-2">
          <h3 className="contact-heading1">CONTACT</h3>
          <p className="address-para">
            <FaMapMarkerAlt className="address-icon" /> DigiAdeiaz Media Pvt.
            Ltd, #630, DHI Innovation Park,Arekere, Bannerghatta Road,
            Bangalore, Karnataka - 560076
          </p>
          <p className="phone-para">
            <FaPhoneAlt className="icon" /> +917338562232
          </p>
          <p className="mail-para">
            <FaEnvelope className="icon" /> info@adeiaz.com
          </p>
        </div>

        <div className="footer-section section-3">
          <h3>QUICK LINKS</h3>
          <Link to="/about" className="footer-link">
            About Us
          </Link>
          <Link to="/advertisers" className="footer-link">
            Advertisers
          </Link>
        </div>

        <div className="footer-section section-4">
          <h3 className="hour">Office Timings:</h3>
          <p>9:30 am to 5:30 pm</p>
        </div>
      </div>

      <div className="footer-divider"></div>

      <div className="footer-copyright">
        <p> &copy; 2024, Powered by DND INFOTECH LLP</p>
      </div>
      <div class="footer-policy">
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                <p>Privacy Policy</p>
            </a>
        </div>
    </footer>
  );
};

export default GlobalFooter;
