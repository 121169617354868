import React, { useState } from "react";
import Home1 from "../Assets/home1.png";
import phone from "../Assets/ph.png";
import location from "../Assets/loc.png";
import mail from "../Assets/mail1.png";
import "./Contact.css";

const Contact = () => {
  const [othersInput, setOthersInput] = useState("");
  const [showSuccessMessage, setshowSuccessMessage] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    role: "",
    message: "",
    countryCode: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "role") {
      if (value === "Others") {
        setOthersInput("");
        setError((prev) => ({ ...prev, role: "" }));
      }
    }
  };

  const handleOthersInputChange = (e) => {
    setOthersInput(e.target.value);
  };


  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!/^\d{10}$/.test(formData.phone))
      errors.phone = "Phone number must be 10 digits.";
    if (!/^\S+@\S+\.\S+$/.test(formData.email))
      errors.email = "Invalid email address.";
    if (!formData.role) errors.role = "Please select an option.";
    if (formData.role === "Others" && !othersInput)
      errors.others = "Please specify.";
    if (!formData.message) errors.message = "Message is required.";
    if (formData.message.length > 250)
      errors.messageLength = "Message can't exceed 250 characters.";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted:", formData, othersInput);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "name": formData.name,
        "email": formData.email,
        "phone": formData.phone,
        "role": formData.role,
        "message": formData.message
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };

      fetch("/handle-contact-us.php", requestOptions).then((resp) => {
        return resp.json();
      }).then(jsonResp => {
        console.log(jsonResp)
        if (jsonResp && jsonResp.status && jsonResp.status == 'success') {
          setshowSuccessMessage(true);
        }
      }).finally(() => {
        setFormData({
          name: "",
          phone: "",
          email: "",
          role: "",
          message: "",
          countryCode: "",
        });
        setOthersInput("");
      })

    }
  };

  return (
    <div className="contact-wrapper">
      <div className="contact-image-container">
        <img src={Home1} alt="about" className="contact-background-image" />
        <h1 className="contact-heading">Get in Touch with Us</h1>
        <h2 className="contact-subheading">
          We are here to help you elevate your brand with the best Mobile
          Digital Out Of Home Advertising Solutions. Whether you have questions,
          need assistance, or want to discuss your advertising needs, we would
          love to hear from you!
        </h2>
      </div>

      <div className="contact-content">
        <div className="contact-info">
          <div className="contact-item">
            <img src={phone} alt="phone" className="contact-icon" />
            <div>
              <h3>Phone</h3>
              <p>+917338562232</p>
            </div>
          </div>
          <div className="contact-item">
            <img src={mail} alt="mail" className="contact-icon" />
            <div>
              <h3>Email</h3>
              <p>info@adeiaz.com</p>
            </div>
          </div>
          <div className="contact-item">
            <img
              src={location}
              alt="location"
              className="contact-address-icon"
            />
            <div>
              <h3 className="contact-address">Location</h3>
              <p className="contact-address">
                DigiAdeiaz Media Pvt. Ltd, #630, DHI Innovation
                Park,Arekere,Bannerghatta Road, Bangalore, Karnataka - 560076
              </p>
            </div>
          </div>
        </div>

        <form className="contact-form" onSubmit={handleSubmit}>
          {showSuccessMessage && (
            <div className="mb-3">
              <p className="success-message">
                Thank you for your interest! Your notification has been
                forwarded to our dedicated sales team and they will be reaching
                out to you shortly. We appreciate your interest and look forward
                to connecting soon!
              </p>
            </div>
          )}
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />

          {error.name && <p className="error">{error.name}</p>}

          <div className="phone-container">
            <input
              className="code"
              type="text"
              name="countryCode"
              placeholder="Country Code"
              value={formData.countryCode}
              onChange={handleChange}
              style={{ width: "160px" }}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          {error.phone && <p className="error">{error.phone}</p>}

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />

          {error.email && <p className="error">{error.email}</p>}

          <select name="role" value={formData.role} onChange={handleChange}>
            <option value="">Who are you?</option>
            <option value="Advertiser (media agency)">
              Advertiser (media agency)
            </option>
            <option value="Advertiser (brand)">Advertiser (brand)</option>
            <option value="Taxi owner">Taxi owner</option>
            <option value="Interested Investor">Interested Investor</option>
            <option value="Join the team">Join the team</option>
            <option value="Others">Others</option>
          </select>

          {error.role && <p className="error">{error.role}</p>}

          {formData.role === "Others" && (
            <input
              type="text"
              placeholder="Please specify"
              value={othersInput}
              onChange={handleOthersInputChange}
              className={error.others ? "error-input" : ""}
            />
          )}

          {error.others && <p className="error">{error.others}</p>}

          <textarea
            className="message-box"
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            maxLength={250}
            style={{ height: "150px" }}
          />

          {error.message && <p className="error">{error.message}</p>}
          {error.messageLength && (
            <p className="error">{error.messageLength}</p>
          )}

          <button type="submit" className="send-button">
            SEND A MESSAGE
          </button>
        </form>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Contact;
